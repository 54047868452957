<template>
  <div class="technician-amo">
    <template v-for="(data, index) in computedData">
      <div class="day" :key="index" :style="computedStyle">
        <DaySummary
          :loading="computedData[index].loading"
          :canEdit="canEdit"
          :month_id="month_id"
          :day="computedData[index].days[0] || []"
          :technician="computedData[index]"
          :depot_zipCode="depot_zipCode"
          :user_zipCode="computedData[index].zipcode"
          :filialeAgence="filialeAgence"
          :planning="computedData"
        />
      </div>
    </template>
  </div>
</template>

<script>
import DaySummary from '@/views/PlanningAMO/DaySummary.vue'

export default {
  name: 'PlanningAMOPlanningRow',
  components: { DaySummary },
  props: {
    data: {
      required: true
    },
    filialeAgence: {
      required: true
    },
    month_id: {
      required: true
    },
    depot_zipCode: {
      required: true
    },
    canEdit: {
      default: false
    }
  },
  computed: {
    computedStyle() {
      if (window.innerWidth <= 500) {
        return {
          'margin-right': window.innerWidth - 30 + 'px'
        }
      }
      return { 'margin-right': 226 + 'px' }
    },
    computedData: function() {
      if (this.data) {
        return this.data
      }
      return { loading: true }
    }
  }
}
</script>

<style lang="scss" scoped>
.technician-amo {
  display: inline-flex;
  margin-bottom: 15px;
  .day {
    display: block;
    text-align: center;
    width: 0px;
    height: 200px;
    padding: 10px 0;
    margin-right: 226px;
    font-size: 10px;
    font-weight: bold;
    // border: 1px solid rgba(0, 0, 0, 0.08);
    // background-color: #f6f6f6;
    color: #656565;

    box-sizing: border-box;
    &.week {
      margin-right: 20px;
      min-width: 280px;
      box-shadow: 4px 0px 6px 0px #dadada;
      z-index: 2;
      position: sticky;
      left: 0;
    }
  }
}
</style>
