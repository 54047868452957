<template>
  <div
    class="page-content"
    :class="{
      'mise-en-page-bureau': estEcranBureau,
      'mise-en-page-mobile': !estEcranBureau
    }"
  >
    <!-- HEADER -->
    <div class="header" v-if="estEcranBureauHeader">
      <div
        class="logo"
        :class="{ default: !computedPlanning.filial_img }"
        :style="{
          'background-image': computedPlanning.filial_img
            ? `url(${computedPlanning.filial_img})`
            : 'none'
        }"
      ></div>
      <form @submit.prevent="hundleSubmit">
        <div class="filters">
          <div class="deposit-number">
            {{
              computedPlanning.depot_nbr ? computedPlanning.depot_nbr : '- -'
            }}
          </div>
        </div>
        <div class="filters">
          <b-form-group
            label="Filiale"
            label-for="filiale-input"
            class="planning-filtres"
          >
            <b-form-select
              id="filiale-input"
              v-model="selectedFiliale"
              @change="hundleFilialeChange"
              :options="getAvailableFiliales"
              value-field="id"
              text-field="name"
              required
            >
              <template
                v-slot:first
                v-if="!getAvailableFiliales || !getAvailableFiliales.length"
              >
                <b-form-select-option :value="null" disabled
                  >-- Aucune filiale disponible --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Agence"
            label-for="deposit-input"
            class="planning-filtres"
          >
            <b-form-select
              id="deposit-input"
              v-model="selectedDeposit"
              :options="getAvailableDeposits"
              @change="hundleChangeFilterDeposite"
              value-field="id"
              text-field="name"
              required
            >
              <template
                v-slot:first
                v-if="!getAvailableDeposits || !getAvailableDeposits.length"
              >
                <b-form-select-option :value="null" disabled
                  >-- Aucune agence disponible --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="filters">
          <b-button
            size="sm"
            variant="outline-primary"
            @click="handleRefresh"
            :disabled="getCPlanningProcessing || getProjectsProcessing"
            title="Actualiser planning"
          >
            <font-awesome-icon class="icon" icon="sync-alt" />
          </b-button>
          <b-spinner
            v-if="getCPlanningProcessing || getProjectsProcessing"
            :style="{
              opacity: getCPlanningProcessing || getProjectsProcessing ? 1 : 0
            }"
            label="Loading..."
          ></b-spinner>
        </div>
        <div class="filters">
          <div v-if="computedErrors" class="error">
            <ul v-if="Array.isArray(computedErrors)">
              <li v-for="(error, index) in computedErrors" :key="index">
                {{ error }}
              </li>
            </ul>
            <span v-else>computedErrors</span>
          </div>
        </div>
      </form>
    </div>
    <div class="header" v-else>
      <div
        class="logo"
        :class="{ default: !computedPlanning.filial_img }"
        :style="{
          'background-image': computedPlanning.filial_img
            ? `url(${computedPlanning.filial_img})`
            : 'none'
        }"
      ></div>
      <div class="show-filiale-agence">
        <div class="filters">
          <div class="deposit-number">
            {{
              computedPlanning.depot_nbr ? computedPlanning.depot_nbr : '- -'
            }}
          </div>
        </div>
      </div>
      <div class="grp-btn">
        <div class="filter-btn" @click="showBtnFilters">
          <font-awesome-icon icon="filter" />
        </div>
        <div class="filters">
          <b-button
            size="sm"
            variant="outline-primary"
            @click="handleRefresh"
            :disabled="getCPlanningProcessing || getProjectsProcessing"
            title="Actualiser planning"
          >
            <font-awesome-icon class="icon" icon="sync-alt" />
          </b-button>
          <!-- <div class="spinner">
            <b-spinner
              v-if="getCPlanningProcessing || getProjectsProcessing"
              :style="{
                opacity: getCPlanningProcessing || getProjectsProcessing ? 1 : 0
              }"
              label="Loading..."
            ></b-spinner>
          </div> -->
        </div>
      </div>
      <div class="filters">
        <div v-if="computedErrors" class="error">
          <ul v-if="Array.isArray(computedErrors)">
            <li v-for="(error, index) in computedErrors" :key="index">
              {{ error }}
            </li>
          </ul>
          <span v-else>computedErrors</span>
        </div>
      </div>
    </div>
    <!-- PLANNING  -->
    <div
      class="content"
      :class="{ minimised: minimised, 'day-stat-on': minimisedDayStat }"
    >
      <div
        class="days"
        :class="{
          'mise-en-page-bureau': estEcranBureau,
          'mise-en-page-mobile': !estEcranBureau
        }"
      >
        <div class="day week">
          Assistants Maître d'ouvrage
          <div class="icon-wrapper" @click="openMinimised">
            <font-awesome-icon
              class="icon"
              :icon="['far', 'caret-square-left']"
            />
          </div>
        </div>
        <div
          class="day capitalize"
          :style="computedStyle(computedPlanning.commercials)"
          v-if="selected && selected.length"
        >
          {{ computedPlanning.dayPlanning }}
        </div>
      </div>
      <div
        class="content-wrapper"
        :class="{
          'mise-en-page-bureau': estEcranBureau,
          'mise-en-page-mobile': !estEcranBureau
        }"
      >
        <div class="week-column">
          <div class="week-summary-amo">
            <div class="block-week" :class="{ minimised: minimised }">
              <div class="week-summary-amo-content">
                <div class="input-date-rdv">
                  <b-form-input
                    v-model="selectedDate"
                    placeholder="DD/MM/YYYY"
                    :disabled="true"
                  ></b-form-input>

                  <div class="icon-date" @click="openDate">
                    <font-awesome-icon class icon="calendar-day" />
                  </div>
                </div>
                <div class="filter-date-rdv mb-2" v-if="openDateInput">
                  <div>
                    <VueCtkDateTimePicker
                      format="DD/MM/YYYY"
                      formatted="l"
                      v-model="selectedDate"
                      :no-label="true"
                      :only-date="true"
                      locale="fr"
                      :no-header="true"
                      :inline="true"
                      input-size="lg"
                      button-color="green"
                      :no-button="true"
                      :auto-close="true"
                      label="DD/MM/YYYY"
                      :max-date="'2024/01/01'"
                      :min-date="'2019/01/01'"
                      button-now-translation="Maintenant"
                      @input="changeValueDate(selectedDate)"
                    />
                  </div>
                </div>
                <span
                  v-if="getCommercialsCPlanningProcessing"
                  class="init-loading three-dots-loading ml-3 mb-3"
                  >Chargement en cours
                </span>
                <template>
                  <div class="check-amo" :class="{ margin: !openDateInput }">
                    <b-form-group
                      v-if="
                        computedCommercials &&
                          computedCommercials.length &&
                          !getCommercialsCPlanningProcessing
                      "
                    >
                      <template #label>
                        <div class="flex-item">
                          <b-form-checkbox
                            v-model="allSelected"
                            :indeterminate="indeterminate"
                            @change="toggleAll"
                            :disabled="injecterRdvAMO ? true : false"
                          >
                            <p class="margin-check">
                              {{
                                allSelected
                                  ? 'Sélectionnez tout'
                                  : indeterminate
                                  ? 'Sélectionnez tout'
                                  : 'Sélectionnez aucun'
                              }}
                            </p>
                          </b-form-checkbox>
                          <div class="btn-planning">
                            <b-button
                              class="icon-filtre"
                              @click="sortBy"
                              v-if="
                                filterCheckedAmo &&
                                  filterCheckedAmo.name &&
                                  filterCheckedAmo.name.length &&
                                  !sortDesc
                              "
                              ><font-awesome-icon
                                class="filter-icon-sort"
                                :icon="['fas', 'caret-down']"
                            /></b-button>
                            <b-button
                              class="icon-filtre"
                              @click="sortBy"
                              v-if="
                                filterCheckedAmo &&
                                  filterCheckedAmo.name &&
                                  filterCheckedAmo.name.length &&
                                  sortDesc
                              "
                              ><font-awesome-icon
                                class="filter-icon-sort"
                                :icon="['fas', 'caret-up']"
                            /></b-button>
                            <b-button
                              class="icon-filtre"
                              @click="openFiltreAmo"
                              v-if="
                                filterCheckedAmo &&
                                  filterCheckedAmo.name &&
                                  filterCheckedAmo.name.length
                              "
                              title="Voir de(s) AMO(s) selectionné(s)"
                              ><font-awesome-icon
                                class="filter-icon"
                                :icon="['fas', 'eye']"
                            /></b-button>
                          </div>
                        </div>
                      </template>

                      <template>
                        <b-form-checkbox-group
                          v-model="selected"
                          class="ml-4"
                          stacked
                        >
                          <b-form-checkbox
                            v-for="(item, index) in computedCommercials"
                            :key="item.id"
                            :value="item"
                            :disabled="
                              injecterRdvAMO
                                ? checkDisabled(item, selected)
                                : false
                            "
                            @change="checkIntervenantAmo(selected, index)"
                          >
                            {{ item.full_name }}
                          </b-form-checkbox>
                        </b-form-checkbox-group>
                      </template>
                    </b-form-group>
                  </div>
                </template>
                <template
                  v-if="
                    (!computedCommercials || !computedCommercials.length) &&
                      !getCommercialsCPlanningProcessing
                  "
                >
                  <span class="msg-aucun ml-3"
                    >Aucun Liste des assistance maitre d'ouvrage
                  </span>
                </template>
              </div>
              <!-- AFFICHAGE DES MESSAGES DES ERREURS DES CONFIRMATION -->
              <div class="message">
                <div v-if="loading" class="three-dots-loading">
                  Chargement en cours
                </div>
                <div v-if="error" class="error-color">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <span v-else>{{ error }}</span>
                </div>
              </div>
              <!-- ACTION DES L4INJECTION DES REDV AMO DANS PLANNINGS  -->
              <!-- <div class="action">
                <b-button
                  variant="outline-secondary"
                  size="sm"
                  @click="hundleCollapseCancel()"
                >
                  Annuler
                </b-button>
                <b-button
                  size="sm"
                  variant="success"
                  @click="hundleCollapseValiderAmo"
                >
                  Enregistrer
                </b-button>
              </div> -->
            </div>
          </div>
        </div>
        <div class="days-column" v-if="selected && selected.length">
          <PlanningRow
            :data="computedPlanning.commercials"
            :canEdit="canEdit"
            :month_id="computedPlanning.month_id"
            :depot_zipCode="computedPlanning.depot_zipCode"
            :filialeAgence="{
              filiale: selectedFiliale,
              agence: selectedDeposit,
              date: selectedDate,
              month: selectedMonth,
              day: selectedDay,
              listeAMO: getListeCommercialsCPlanning,
              amo: selectedAMO
            }"
          />
        </div>

        <div
          v-if="
            allSelected == indeterminate &&
              !getCPlanningProcessing &&
              !getCommercialsCPlanningProcessing &&
              !notSelectedAmo
          "
          class="info-msg"
        >
          Aucun Assistant Maître d'ouvrage sélectionné
        </div>
      </div>
    </div>
    <!--  -->
    <b-modal
      id="modal-center-warning"
      no-close-on-backdrop
      ref="modal-center-warning"
      centered
      :hide-footer="true"
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <div class="info">
          <font-awesome-icon class="icon-info" icon="info-circle" />
          <span class="margin-label-info">Information</span>
        </div>
        <b-button
          size="sm"
          variant="outline-danger"
          @click="closeModal('modal-center-warning')"
          class="btn-filter-close-info"
          title="Fermer"
        >
          <span @click="close()"> X</span>
        </b-button>
      </template>
      <p>
        De(s) AMO(s) sélectionné(s) qui ne sont pas affectés à cette date<span
          class="font"
        >
          {{ selectedDate }}</span
        >.
      </p>
      <!-- si il y a des amo non disponible dans la liste  -->
      <b-tabs
        class="amo-tabs"
        lazy
        v-if="
          amoNonDisponibleList &&
            amoNonDisponibleList.name &&
            amoNonDisponibleList.name.length
        "
      >
        <b-tab
          :title-link-class="linkClass(item)"
          v-for="(item, index) in amoNonDisponibleList.name"
          :key="'item' + index"
        >
          <template class="counter" #title>
            <span>
              {{ item }}
            </span>
            <span
              :title="'Déselctionner ' + item + ' de la liste'"
              @click="
                deletedAmoLocalStorage(
                  amoNonDisponibleList.id[index],
                  'modal-center-warning'
                )
              "
              class="icon"
            >
              X
            </span>
          </template>
        </b-tab>
      </b-tabs>
    </b-modal>
    <!-- Afficher les amo selectionner dans localStorage  -->
    <b-modal
      id="modal-center-amo"
      no-close-on-backdrop
      ref="modal-center-amo"
      centered
      :hide-footer="true"
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h6>De(s) AMO(s) sélectionné(s) dans le filtre de recherche</h6>

        <b-button
          size="sm"
          variant="outline-danger"
          @click="closeModal('modal-center-amo')"
          class="btn-filter-close-info"
          title="Fermer"
        >
          <span @click="close"> X</span>
        </b-button>
      </template>
      <div class="label-div">
        De(s) AMO(s) disponible(s) à cette liste
      </div>
      <b-tabs
        class="amo-tabs"
        lazy
        v-if="amoDisponibleInList && amoDisponibleInList.length"
      >
        <b-tab
          :title-link-class="linkClass(item)"
          v-for="(item, index) in amoDisponibleInList"
          :key="'item' + index"
        >
          <template class="counter" #title>
            <span>
              {{ item.full_name }}
            </span>
            <!-- <span
              :title="'Déselctionner ' + item.full_name + ' de la liste'"
              @click="deletedAmoLocalStorage(item.id, 'modal-center-amo')"
              class="icon"
            >
              X
            </span> -->
          </template>
        </b-tab>
      </b-tabs>
      <div class="not-block" v-else>Aucun</div>
      <div class="label-div">
        De(s) AMO(s) non disponible(s) à cette liste
      </div>
      <b-tabs
        class="amo-tabs"
        lazy
        v-if="
          amoNonDisponibleList &&
            amoNonDisponibleList.name &&
            amoNonDisponibleList.name.length
        "
      >
        <b-tab
          :title-link-class="linkClass(item)"
          v-for="(item, index) in amoNonDisponibleList.name"
          :key="'item' + index"
        >
          <template class="counter" #title>
            <span>
              {{ item }}
            </span>
            <span
              :title="'Déselctionner ' + item + ' de la liste'"
              @click="
                deletedAmoLocalStorage(
                  amoNonDisponibleList.id[index],
                  'modal-center-amo'
                )
              "
              class="icon"
            >
              X
            </span>
          </template>
        </b-tab>
      </b-tabs>
      <div class="not-block" v-else>Aucun</div>
    </b-modal>
    <!-- MODAL RESPONSIVE FILTER -->
    <b-modal
      id="modal-center-filter"
      ref="modal-center-filter"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <b-button
          size="sm"
          variant="outline-danger"
          @click="close()"
          class="btn-filter-close"
        >
          X
        </b-button>
      </template>

      <form @submit.prevent="hundleSubmit">
        <div class="filters">
          <b-form-group
            label="Filiale"
            label-for="filiale-input"
            class="planning-filtres"
          >
            <b-form-select
              id="filiale-input"
              v-model="selectedFiliale"
              @change="hundleFilialeChange"
              :options="getAvailableFiliales"
              value-field="id"
              text-field="name"
              required
            >
              <template
                v-slot:first
                v-if="!getAvailableFiliales || !getAvailableFiliales.length"
              >
                <b-form-select-option :value="null" disabled
                  >-- Aucune filiale disponible --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Agence"
            label-for="deposit-input"
            class="planning-filtres"
          >
            <b-form-select
              id="deposit-input"
              v-model="selectedDeposit"
              :options="getAvailableDeposits"
              @change="hundleChangeFilterDeposite"
              value-field="id"
              text-field="name"
              required
            >
              <template
                v-slot:first
                v-if="!getAvailableDeposits || !getAvailableDeposits.length"
              >
                <b-form-select-option :value="null" disabled
                  >-- Aucune agence disponible --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="filters">
          <div v-if="computedErrors" class="error">
            <ul v-if="Array.isArray(computedErrors)">
              <li v-for="(error, index) in computedErrors" :key="index">
                {{ error }}
              </li>
            </ul>
            <span v-else>computedErrors</span>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import PlanningRow from '@/views/PlanningAMO/PlanningRow.vue'
// import WeekSummary from '@/views/PlanningAMO/WeekSummary.vue'
// import DayStatRow from '@/views/PlanningAMO/DayStatRow.vue'
// import TooltipAddAmo from '@/views/PlanningAMO/TooltipAddAmo.vue'
// import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')

export default {
  name: 'planning-amo',
  components: {
    PlanningRow,
    // WeekSummary,
    VueCtkDateTimePicker
    // DayStatRow
    // TooltipAddAmo,
    // Multiselect
  },
  data() {
    return {
      selectedYear: null,
      selectedMonth: null,
      selectedMonthNbr: null,
      selectedDay: null,
      selectedDate: null,
      selectedFiliale: null,
      selectedDeposit: null,
      canEdit: false,
      localError: null,
      errorInjection: null,
      minimised: false,
      minimisedDayStat: false,
      openPopup: false,
      ref_tab: [],
      commercial_ids: [],
      selected: [],
      allSelected: false,
      indeterminate: false,
      map_id_amo: null,
      error: [],
      loading: false,
      confirmeDeltedInjection: false,
      buttonEnregistre: false,
      injecterRdvAMO: false,
      annulerInjection: false,
      openDateInput: true,
      selectedAMO: null,
      notSelectedAmo: false,
      filterCheckedAmo: null,
      amoDisponibleInList: null,
      amoNonDisponibleList: null,
      estEcranBureau: true,
      showBtnFilter: false,
      estEcranBureauHeader: true,
      sortDesc: false
    }
  },
  methods: {
    ...mapActions([
      'fetchFiliales',
      'fetchDeposits',
      'fetchYears',
      'fetchMonths',
      'initCPlanning',
      'fetchPipeline',
      'fetchCPlanningAmo',
      'fetchDays',
      'fetchListeCommercialCPlanning',
      'refreshPlanning',
      'resetErrorProject',
      'updateProject'
    ]),
    sortBy() {
      this.sortDesc = !this.sortDesc
      if (!this.sortDesc) {
        this.fetchListeCommercialCPlanning({
          depot_id: this.selectedDeposit,
          month_id: this.selectedMonth,
          day_nbr: this.selectedDay,
          planning: 'planning-amo',
          order: 'asc',
          loading: false
        }).then(async () => {
          if (this.getListeCommercialsCPlanning) {
            this.selectedYear = this.getListeCommercialsCPlanning.year
            this.selectedMonth = this.getListeCommercialsCPlanning.month_id
          }
          this.fetchPlanningCommerciaux(true)
        })
      } else {
        this.fetchListeCommercialCPlanning({
          depot_id: this.selectedDeposit,
          month_id: this.selectedMonth,
          day_nbr: this.selectedDay,
          planning: 'planning-amo',
          order: 'desc',
          loading: false
        }).then(async () => {
          if (this.getListeCommercialsCPlanning) {
            this.selectedYear = this.getListeCommercialsCPlanning.year
            this.selectedMonth = this.getListeCommercialsCPlanning.month_id
          }
          this.fetchPlanningCommerciaux(true)
        })
      }
    },
    openMinimised() {
      this.minimised = !this.minimised
    },
    showBtnFilters() {
      this.showBtnFilter = !this.showBtnFilter
      this.$refs['modal-center-filter'].show()
    },
    onResize() {
      this.estEcranBureau = window.innerWidth >= 827 // ajustez le point de rupture selon vos besoins
      this.estEcranBureauHeader = window.innerWidth >= 500 // ajustez le point de rupture selon vos besoins
      if (window.innerWidth >= 827) {
        this.minimised = false
      } else {
        this.minimised = true
      }
      // STYLE INPUT CALENDER
      if (window.innerWidth <= 500 && window.innerWidth > 350) {
        document.getElementsByClassName(
          'datepicker flex flex-direction-column'
        )[0].style.maxWidth = '370px'
        document.getElementsByClassName(
          'datepicker flex flex-direction-column'
        )[0].style.minWidth = '223px'
        document.getElementsByClassName(
          'datepicker flex flex-direction-column'
        )[0].style.width = '306px'
        document.getElementsByClassName(
          'datepicker-container flex flex-fixed flex-1 inline'
        )[0].style.width = '272px'
      } else {
        document.getElementById(
          'undefined-picker-container-DatePicker'
        ).style.width = '224px'
        document.getElementById(
          'undefined-picker-container-DatePicker'
        ).style.height = '253px'
        document.getElementsByClassName('month-container')[0].style.height =
          '190px'
        document.getElementsByClassName(
          'datepicker-day align-center justify-content-center'
        )[0].style.height = '30px'
        document.getElementsByClassName('week-days flex')[0].style.height =
          '32px'
        document.getElementsByClassName(
          'datepicker-controls flex align-center justify-content-center'
        )[0].style.height = '35px'
        document.getElementsByClassName(
          'datepicker flex flex-direction-column'
        )[0].style.maxWidth = '230px'
        document.getElementsByClassName(
          'datepicker flex flex-direction-column'
        )[0].style.minWidth = '223px'
        document.getElementsByClassName(
          'datepicker flex flex-direction-column'
        )[0].style.width = '230px'
      }
    },
    linkClass() {
      return ['amo-tab-title']
    },
    openFiltreAmo() {
      this.$refs['modal-center-amo'].show()
    },
    closeModal(ref) {
      if (!this.selected || !this.selected.length) {
        this.allSelected = false
        this.indeterminate = false
        this.notSelectedAmo = false
      }
      this.$refs[ref].hide()
    },
    deletedAmoLocalStorage(idAmo, ref) {
      //Rechercher idAmo amo dans table localstorage checkedAmo
      if (idAmo) {
        let keyIndex = JSON.parse(localStorage.getItem('checkedAmo'))
          .id.map((item, key) => {
            if (item == idAmo) {
              return key
            }
          })
          .find(item => item != null)
        // Supprimer ids amo dans local storage
        if (
          keyIndex != null &&
          JSON.parse(localStorage.getItem('checkedAmo')) &&
          JSON.parse(localStorage.getItem('checkedAmo')).id &&
          JSON.parse(localStorage.getItem('checkedAmo')).id.length
        ) {
          let deleteAmoId = JSON.parse(
            localStorage.getItem('checkedAmo')
          ).id.filter((item, key) => key != keyIndex)
          let deleteAmoName = JSON.parse(
            localStorage.getItem('checkedAmo')
          ).name.filter((item, key) => key != keyIndex)
          localStorage.setItem(
            'checkedAmo',
            JSON.stringify({ id: deleteAmoId, name: deleteAmoName })
          )
          this.notSelectedAmo = true
        }
      }
      // supprimer amo si exist amo id and date id and deja supprimer dans checked amo
      if (
        JSON.parse(localStorage.getItem('amo')) &&
        JSON.parse(localStorage.getItem('amo')).amo_id &&
        JSON.parse(localStorage.getItem('amo')).date_rdv == null
      ) {
        localStorage.setItem(
          'amo',
          JSON.stringify({
            agence: null,
            amo_id: null,
            date_fin: null,
            date_rdv: null,
            entity: JSON.parse(localStorage.getItem('amo')).entity
          })
        )
      }
      // Affecter localStorage de checkedAmo
      this.filterCheckedAmo = JSON.parse(localStorage.getItem('checkedAmo'))
      // Afficher les amo dispo et non dispo de la liste
      this.groupeAmoListe()
      // Fermer Modal si supprimer tous las amo non disponible dans la liste
      if (
        this.amoNonDisponibleList &&
        this.amoNonDisponibleList.name &&
        !this.amoNonDisponibleList.name.length &&
        ref == 'modal-center-warning'
      ) {
        this.closeModal(ref)
      }
      if (
        ref == 'modal-center-amo' &&
        (!this.amoDisponibleInList || !this.amoDisponibleInList.length) &&
        this.amoNonDisponibleList &&
        this.amoNonDisponibleList.name &&
        !this.amoNonDisponibleList.name.length
      ) {
        this.closeModal(ref)
      }
    },
    checkIntervenantAmo(selectedAmo, index) {
      // liste des amo selectionner
      let map_id_amo =
        selectedAmo && selectedAmo.length
          ? selectedAmo.map(item => item.id)
          : []
      let map_full_name_amo =
        selectedAmo && selectedAmo.length
          ? selectedAmo.map(item => item.full_name)
          : []
      // un seul amo selectionner a chaque click
      let amo_id = []
      let amo_name = []
      if (this.computedCommercials && this.computedCommercials.length) {
        amo_id = this.computedCommercials
          .filter((item, key) => key == index)
          .map(item => item.id)
        amo_name = this.computedCommercials
          .filter((item, key) => key == index)
          .map(item => item.full_name)
      }
      if (
        JSON.parse(localStorage.getItem('checkedAmo')) &&
        JSON.parse(localStorage.getItem('checkedAmo')).id &&
        JSON.parse(localStorage.getItem('checkedAmo')).id.length
      ) {
        if (
          map_id_amo &&
          JSON.parse(localStorage.getItem('checkedAmo')).id &&
          (map_id_amo.length ==
            JSON.parse(localStorage.getItem('checkedAmo')).id.length ||
            JSON.parse(localStorage.getItem('checkedAmo')).id.length >
              map_id_amo.length)
        ) {
          if (
            !JSON.parse(localStorage.getItem('checkedAmo')).id.includes(
              ...amo_id
            )
          ) {
            localStorage.setItem(
              'checkedAmo',
              JSON.stringify({
                id: JSON.parse(localStorage.getItem('checkedAmo')).id.concat(
                  ...amo_id
                ),
                name: JSON.parse(
                  localStorage.getItem('checkedAmo')
                ).name.concat(...amo_name)
              })
            )
          } else if (
            JSON.parse(localStorage.getItem('checkedAmo')).id.includes(
              ...amo_id
            )
          ) {
            let deleteAmoId = JSON.parse(
              localStorage.getItem('checkedAmo')
            ).id.filter(item => item != amo_id[0])
            let deleteAmoName = JSON.parse(
              localStorage.getItem('checkedAmo')
            ).name.filter(item => item != amo_name[0])
            localStorage.setItem(
              'checkedAmo',
              JSON.stringify({ id: deleteAmoId, name: deleteAmoName })
            )
          }
        } else if (
          map_id_amo &&
          JSON.parse(localStorage.getItem('checkedAmo')).id &&
          map_id_amo.length >
            JSON.parse(localStorage.getItem('checkedAmo')).id.length
        ) {
          localStorage.setItem(
            'checkedAmo',
            JSON.stringify({ id: map_id_amo, name: map_full_name_amo })
          )
        }
      } else {
        localStorage.setItem(
          'checkedAmo',
          JSON.stringify({ id: map_id_amo, name: map_full_name_amo })
        )
      }
      // supprimer amo si exist amo id and date id and deja supprimer dans checked amo
      if (
        JSON.parse(localStorage.getItem('amo')) &&
        JSON.parse(localStorage.getItem('amo')).amo_id &&
        JSON.parse(localStorage.getItem('amo')).date_rdv == null
      ) {
        localStorage.setItem(
          'amo',
          JSON.stringify({
            agence: null,
            amo_id: null,
            date_fin: null,
            date_rdv: null,
            entity: JSON.parse(localStorage.getItem('amo')).entity
          })
        )
      } else if (
        JSON.parse(localStorage.getItem('amo')) &&
        JSON.parse(localStorage.getItem('amo')).amo_id
      ) {
        localStorage.removeItem('amo')
      }
      this.resetErrorProject()
      this.hundleCollapseValider(map_id_amo)
      // Affecter localStorage de checkedAmo
      this.filterCheckedAmo = JSON.parse(localStorage.getItem('checkedAmo'))
      // Afficher les amo dispo et non dispo de la liste
      this.groupeAmoListe()
    },
    groupeAmoListe() {
      // Affichier les amo dispo et non dispo de la liste
      if (
        JSON.parse(localStorage.getItem('checkedAmo')) &&
        JSON.parse(localStorage.getItem('checkedAmo')).id &&
        JSON.parse(localStorage.getItem('checkedAmo')).id.length
      ) {
        this.amoDisponibleInList = this.computedCommercials.filter(val =>
          JSON.parse(localStorage.getItem('checkedAmo')).id.includes(val.id)
        )
        if (this.amoDisponibleInList && this.amoDisponibleInList.length) {
          let formatId = this.amoDisponibleInList.map(item => item.id)
          let formatName = this.amoDisponibleInList.map(item => item.full_name)
          this.amoNonDisponibleList = {
            id: JSON.parse(localStorage.getItem('checkedAmo')).id.filter(
              val => !formatId.includes(val)
            ),
            name: JSON.parse(localStorage.getItem('checkedAmo')).name.filter(
              val => !formatName.includes(val)
            )
          }
        } else {
          this.amoNonDisponibleList = {
            id: JSON.parse(localStorage.getItem('checkedAmo')).id,
            name: JSON.parse(localStorage.getItem('checkedAmo')).name
          }
        }
      } else {
        this.amoDisponibleInList = []
        this.amoNonDisponibleList = {
          id: [],
          name: []
        }
      }
    },
    openDate() {
      this.openDateInput = !this.openDateInput
      // STYLE INPUT CALENDER
      this.$nextTick(() => {
        if (window.innerWidth <= 500 && window.innerWidth > 350) {
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.maxWidth = '370px'
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.minWidth = '223px'
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.width = '306px'
          document.getElementsByClassName(
            'datepicker-container flex flex-fixed flex-1 inline'
          )[0].style.width = '272px'
        } else {
          document.getElementById(
            'undefined-picker-container-DatePicker'
          ).style.width = '224px'
          document.getElementById(
            'undefined-picker-container-DatePicker'
          ).style.height = '253px'
          document.getElementsByClassName('month-container')[0].style.height =
            '190px'
          document.getElementsByClassName(
            'datepicker-day align-center justify-content-center'
          )[0].style.height = '30px'
          document.getElementsByClassName('week-days flex')[0].style.height =
            '32px'
          document.getElementsByClassName(
            'datepicker-controls flex align-center justify-content-center'
          )[0].style.height = '35px'
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.maxWidth = '230px'
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.minWidth = '223px'
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.width = '230px'
        }
      })
    },
    toggleAll(checked) {
      if (this.injecterRdvAMO) {
        this.allSelected = false
      } else {
        if (checked) {
          this.selected = checked ? this.computedCommercials.slice() : []
          if (
            JSON.parse(localStorage.getItem('checkedAmo')) &&
            JSON.parse(localStorage.getItem('checkedAmo')).id &&
            JSON.parse(localStorage.getItem('checkedAmo')).id.length
          ) {
            let amoId = this.selected.map(item => item.id)
            let amoFulllName = this.selected.map(item => item.full_name)
            let amoIdNonDispoList = JSON.parse(
              localStorage.getItem('checkedAmo')
            ).id.filter(val => !amoId.includes(val))
            let amoNameNonDispoList = JSON.parse(
              localStorage.getItem('checkedAmo')
            ).name.filter(val => !amoFulllName.includes(val))
            let groupeId =
              amoIdNonDispoList && amoIdNonDispoList.length
                ? amoId.concat(amoIdNonDispoList).filter(item => item != null)
                : []
            let groupeName =
              amoNameNonDispoList && amoNameNonDispoList.length
                ? amoFulllName
                    .concat(amoNameNonDispoList)
                    .filter(item => item != null)
                : []
            localStorage.setItem(
              'checkedAmo',
              JSON.stringify({
                id:
                  groupeId && groupeId.length
                    ? groupeId
                    : this.selected && this.selected.length
                    ? this.selected.map(item => item.id)
                    : [],
                name:
                  groupeName && groupeName.length
                    ? groupeName
                    : this.selected && this.selected.length
                    ? this.selected.map(item => item.full_name)
                    : []
              })
            )
          } else {
            localStorage.setItem(
              'checkedAmo',
              JSON.stringify({
                id:
                  this.selected && this.selected.length
                    ? this.selected.map(item => item.id)
                    : [],
                name:
                  this.selected && this.selected.length
                    ? this.selected.map(item => item.full_name)
                    : []
              })
            )
          }
        } else {
          this.selected = []
          this.indeterminate = false
          this.allSelected = false
          this.notSelectedAmo = false
          if (
            this.amoNonDisponibleList &&
            this.amoNonDisponibleList.id &&
            this.amoNonDisponibleList.id.length
          ) {
            localStorage.setItem(
              'checkedAmo',
              JSON.stringify(this.amoNonDisponibleList)
            )
          } else {
            localStorage.setItem(
              'checkedAmo',
              JSON.stringify({
                id:
                  this.selected && this.selected.length
                    ? this.selected.map(item => item.id)
                    : [],
                name:
                  this.selected && this.selected.length
                    ? this.selected.map(item => item.full_name)
                    : []
              })
            )
          }
        }
        let map_id_amo =
          this.selected && this.selected.length
            ? this.selected.map(item => item.id)
            : []
        this.resetErrorProject()
        this.hundleCollapseValider(map_id_amo)
        // Affecter localStorage de checkedAmo
        this.filterCheckedAmo = JSON.parse(localStorage.getItem('checkedAmo'))
        // Afficher les amo dispo et non dispo de la liste
        this.groupeAmoListe()
      }
    },
    selectedAllAmoParDefaut() {
      if (this.computedCommercials && this.computedCommercials.length) {
        this.indeterminate = false
        this.allSelected = true
        this.selected = this.computedCommercials
        // Afficher icon filter and affecter  id amo  dans checkedamo
        localStorage.setItem(
          'checkedAmo',
          JSON.stringify({
            id:
              this.selected && this.selected.length
                ? this.selected.map(item => item.id)
                : [],
            name:
              this.selected && this.selected.length
                ? this.selected.map(item => item.full_name)
                : []
          })
        )
        // Affecter localStorage de checkedAmo
        this.filterCheckedAmo = JSON.parse(localStorage.getItem('checkedAmo'))
        // Afficher les amo dispo et non dispo de la liste
        this.groupeAmoListe()
      }
    },
    hundleCollapseValiderAmo() {
      this.map_id_amo = []
      let tab = []
      if (
        this.selected &&
        this.selected.length &&
        this.computedCommercials &&
        this.computedCommercials.length
      ) {
        for (let index = 0; index < this.computedCommercials.length; index++) {
          const amo_computed = this.computedCommercials[index]
          for (let j = 0; j < this.selected.length; j++) {
            const selected = this.selected[j]
            if (selected.id == amo_computed.id) {
              tab.push(amo_computed)
            }
          }
        }
      }
      if (tab && tab.length) {
        this.map_id_amo = tab.map(item => item.id)
      }
      this.resetErrorProject()
      this.hundleCollapseValider(this.map_id_amo)
    },
    async hundleCollapseValider(commercial_ids) {
      if (commercial_ids && commercial_ids.length) {
        if (!this.selectedDeposit || !this.selectedDate) {
          const localError = []
          if (!this.selectedDeposit) {
            localError.push("L'agence est obligatoire")
          }
          if (!this.selectedDate) {
            localError.push('La date de RDV est obligatoire')
          }
          this.localError = localError
          return
        }
        if (
          JSON.parse(localStorage.getItem('amo')) &&
          JSON.parse(localStorage.getItem('checkedAmo')) &&
          JSON.parse(localStorage.getItem('checkedAmo')).id &&
          JSON.parse(localStorage.getItem('checkedAmo')).id.length
        ) {
          let includeValueWithLocalStorage = []
          let amo_id = []
          if (this.computedCommercials && this.computedCommercials.length) {
            amo_id = this.computedCommercials.map(item => item.id)
            includeValueWithLocalStorage = amo_id.filter(val =>
              JSON.parse(localStorage.getItem('checkedAmo')).id.includes(val)
            )
          }
          if (
            includeValueWithLocalStorage &&
            includeValueWithLocalStorage.length
          ) {
            if (!this.sortDesc) {
              await this.initCPlanning({
                depot_id: this.selectedDeposit,
                month_id: this.selectedMonth,
                day_nbr: this.selectedDay,
                comm_ids: includeValueWithLocalStorage,
                planning: 'planning-amo',
                order: 'asc'
              })
            } else {
              await this.initCPlanning({
                depot_id: this.selectedDeposit,
                month_id: this.selectedMonth,
                day_nbr: this.selectedDay,
                comm_ids: includeValueWithLocalStorage,
                planning: 'planning-amo',
                order: 'desc'
              })
            }
            this.fetchCPlanningAmo({
              planning: 'planning-amo',
              day_nbr: this.selectedDay,
              comm_ids: includeValueWithLocalStorage
            })
          }
        } else {
          if (!this.sortDesc) {
            await this.initCPlanning({
              depot_id: this.selectedDeposit,
              month_id: this.selectedMonth,
              day_nbr: this.selectedDay,
              comm_ids: commercial_ids,
              planning: 'planning-amo',
              order: 'asc'
            })
          } else {
            await this.initCPlanning({
              depot_id: this.selectedDeposit,
              month_id: this.selectedMonth,
              day_nbr: this.selectedDay,
              comm_ids: commercial_ids,
              planning: 'planning-amo',
              order: 'desc'
            })
          }
          this.fetchCPlanningAmo({
            planning: 'planning-amo',
            day_nbr: this.selectedDay,
            comm_ids: commercial_ids
          })
        }
      } else {
        this.refreshPlanning()
      }
      // if (
      //   JSON.parse(localStorage.getItem('amo')) &&
      //   JSON.parse(localStorage.getItem('amo')).amo_id != null
      // ) {
      //   localStorage.removeItem('amo')
      // }
    },
    hundleCollapseCancel() {
      this.minimised = !this.minimised
    },
    selectedAmoProjetModal(removeAmo) {
      if (
        this.computedCommercials &&
        this.computedCommercials.length &&
        JSON.parse(localStorage.getItem('amo')) &&
        JSON.parse(localStorage.getItem('amo')).amo_id
      ) {
        if (
          JSON.parse(localStorage.getItem('amo')) &&
          JSON.parse(localStorage.getItem('checkedAmo')) &&
          JSON.parse(localStorage.getItem('checkedAmo')).id &&
          JSON.parse(localStorage.getItem('checkedAmo')).id.length
        ) {
          this.selectedAMO = JSON.parse(localStorage.getItem('checkedAmo')).id
          let tab = []
          for (
            let index = 0;
            index < this.computedCommercials.length;
            index++
          ) {
            const amo_computed = this.computedCommercials[index]
            for (
              let j = 0;
              j < JSON.parse(localStorage.getItem('checkedAmo')).id.length;
              j++
            ) {
              if (
                JSON.parse(localStorage.getItem('checkedAmo')).id[j] ==
                amo_computed.id
              ) {
                tab.push(amo_computed)
              }
            }
          }
          if (tab && tab.length) {
            this.indeterminate = false
            this.allSelected = true
            this.selected = tab
          }
        } else {
          this.selectedAMO = [JSON.parse(localStorage.getItem('amo')).amo_id]
          let tab = []
          for (
            let index = 0;
            index < this.computedCommercials.length;
            index++
          ) {
            const amo_computed = this.computedCommercials[index]
            if (
              JSON.parse(localStorage.getItem('amo')).amo_id == amo_computed.id
            ) {
              tab.push(amo_computed)
            }
          }
          if (tab && tab.length) {
            this.indeterminate = false
            this.allSelected = true
            this.selected = tab
          }
        }
      }
      if (removeAmo) {
        localStorage.removeItem('amo')
      }
    },
    changeValueDate(date) {
      // this.resetErrorProject()
      // STYLE INPUT CALENDER
      this.selectedDate = moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')
      if (window.innerWidth <= 500 && window.innerWidth > 350) {
        document.getElementsByClassName(
          'datepicker flex flex-direction-column'
        )[0].style.maxWidth = '370px'
        document.getElementsByClassName(
          'datepicker flex flex-direction-column'
        )[0].style.minWidth = '223px'
        document.getElementsByClassName(
          'datepicker flex flex-direction-column'
        )[0].style.width = '306px'
        document.getElementsByClassName(
          'datepicker-container flex flex-fixed flex-1 inline'
        )[0].style.width = '272px'
      } else {
        document.getElementById(
          'undefined-picker-container-DatePicker'
        ).style.width = '224px'
        document.getElementById(
          'undefined-picker-container-DatePicker'
        ).style.height = '253px'
        document.getElementsByClassName('month-container')[0].style.height =
          '190px'
        document.getElementsByClassName(
          'datepicker-day align-center justify-content-center'
        )[0].style.height = '30px'
        document.getElementsByClassName('week-days flex')[0].style.height =
          '32px'
        document.getElementsByClassName(
          'datepicker-controls flex align-center justify-content-center'
        )[0].style.height = '35px'
        document.getElementsByClassName(
          'datepicker flex flex-direction-column'
        )[0].style.maxWidth = '230px'
        document.getElementsByClassName(
          'datepicker flex flex-direction-column'
        )[0].style.minWidth = '223px'
        document.getElementsByClassName(
          'datepicker flex flex-direction-column'
        )[0].style.width = '230px'
      }

      // CONVERTIR LA DATE EN NUMERIQUE
      if (date) {
        this.selectedYear = parseInt(date.split('/')[2])
        this.selectedDay = parseInt(date.split('/')[0])
        this.selectedMonthNbr = parseInt(date.split('/')[1])
        this.convertMonthNbr(
          this.selectedMonthNbr,
          this.selectedYear,
          this.selectedDay
        )
      }
      // Affecter localStorage de checkedAmo
      this.filterCheckedAmo = JSON.parse(localStorage.getItem('checkedAmo'))
    },
    async convertMonthNbr(selectMonth, selectYear, selectDay) {
      // get months of current year
      if (this.currentYear != selectYear) {
        await this.fetchMonths(selectYear)
        this.getAvailableMonths.forEach(month => {
          if (month.month_nbr == selectMonth) {
            this.selectedMonth = month.id
            this.selectedMonthNbr = selectMonth
          }
        })
      }
      // select default month
      this.getAvailableMonths.forEach(month => {
        if (month.month_nbr == selectMonth) {
          this.selectedMonth = month.id
          this.currentYear = month.year
          this.selectedMonthNbr = selectMonth
        }
      })
      // get day
      let month = null
      if (this.selectedMonth) {
        month = this.getAvailableMonths.find(item => {
          if (item.id == this.selectedMonth) {
            return item
          }
        })
      }
      if (month && month.month_nbr) {
        await this.fetchDays({ month: month.month_nbr, year: month.year })
      }
      this.selectedDay = selectDay
      this.hundleChangeFilter(false)
    },
    async hundleFilialeChange() {
      this.selectedDeposit = null
      this.fetchDeposits(this.selectedFiliale)
      this.resetErrorProject()
      localStorage.removeItem('checkedAmo')
    },
    hundleChangeFilterDeposite() {
      this.hundleChangeFilter(true)
      this.resetErrorProject()
    },
    hundleChangeFilter(removeAmo) {
      if (
        this.selectedDeposit &&
        this.selectedMonth &&
        this.selectedDay &&
        this.selectedDate
      ) {
        this.$router
          .replace(
            '/planning-amo/agence/' +
              this.selectedDeposit +
              '/mois/' +
              this.selectedMonth +
              '/jour/' +
              this.selectedDay
          )
          .catch(() => {})
      }
      this.hundleSubmit(removeAmo)
    },
    checkForPermission() {
      if (this.getAvailableDeposits) {
        this.getAvailableDeposits.forEach(d => {
          if (d.id == this.selectedDeposit) {
            this.canEdit = d.can_edit || this.currentUser.isSuper ? true : false
          }
        })
      }
    },
    async hundleSubmit(removeAmo) {
      if (!this.selectedDeposit || !this.selectedDate) {
        const localError = []
        if (!this.selectedDeposit) {
          localError.push("L'agence est obligatoire")
        }
        if (!this.selectedDate) {
          localError.push('La date de RDV est obligatoire')
        }
        this.localError = localError
        return
      }
      if (
        this.selectedYear &&
        this.selectedDeposit &&
        this.selectedMonth &&
        this.selectedDay
      ) {
        if (removeAmo) {
          this.sortDesc = false
        }
        this.refreshPlanning()
        // FETCH LISTE DES AMO
        if (!this.sortDesc) {
          await this.fetchListeCommercialCPlanning({
            depot_id: this.selectedDeposit,
            month_id: this.selectedMonth,
            day_nbr: this.selectedDay,
            planning: 'planning-amo',
            order: 'asc'
          })
        } else {
          await this.fetchListeCommercialCPlanning({
            depot_id: this.selectedDeposit,
            month_id: this.selectedMonth,
            day_nbr: this.selectedDay,
            planning: 'planning-amo',
            order: 'desc'
          })
        }
        if (this.getListeCommercialsCPlanning) {
          if (this.selectedYear != this.getListeCommercialsCPlanning.year) {
            this.selectedYear = this.getListeCommercialsCPlanning.year
            await this.fetchMonths(this.selectedYear)
            this.selectedMonth = this.getListeCommercialsCPlanning.month_id
          }
        }
        if (removeAmo) {
          localStorage.removeItem('amo')
          localStorage.removeItem('checkedAmo')
        }
        // FETCH DAY PLANNING AMO
        this.fetchPlanningCommerciaux(false)
        if (!this.estEcranBureau) {
          this.$nextTick(() => {
            this.$refs['modal-center-filter'].hide()
          })
        }
      }
    },
    async handleRefresh(value_amo) {
      this.filterParamsUrl(value_amo)
    },
    async fetchPlanningCommerciaux(sortBy) {
      // reset message error
      this.localError = null
      this.resetErrorProject()

      //
      this.checkForPermission()
      /*  ~2) GET PIPELINE */
      if (!this.getPlanningPipeline || !this.getPlanningPipeline.length) {
        this.fetchPipeline()
      }
      /* ~3) FETCH PLANNINGS PER TECHNICIAN */
      // AMO ET DATE RDV LES DEUX EXISTE
      if (JSON.parse(localStorage.getItem('amo'))) {
        // cas Ajouter autre amo dans checkbox (2 amo ou plus )
        if (
          JSON.parse(localStorage.getItem('amo')) &&
          JSON.parse(localStorage.getItem('checkedAmo')) &&
          JSON.parse(localStorage.getItem('checkedAmo')).id &&
          JSON.parse(localStorage.getItem('checkedAmo')).id.length
        ) {
          let includeValueWithLocalStorage = []
          let amo_id = []
          if (this.computedCommercials && this.computedCommercials.length) {
            amo_id = this.computedCommercials.map(item => item.id)
            includeValueWithLocalStorage = amo_id.filter(val =>
              JSON.parse(localStorage.getItem('checkedAmo')).id.includes(val)
            )
          }
          if (
            includeValueWithLocalStorage &&
            includeValueWithLocalStorage.length
          ) {
            // quelque soit les condition de date rdv
            if (
              JSON.parse(localStorage.getItem('amo')) &&
              JSON.parse(localStorage.getItem('amo')).amo_id &&
              JSON.parse(localStorage.getItem('amo')).agence
            ) {
              this.selectedAMO = [
                JSON.parse(localStorage.getItem('amo')).amo_id
              ].concat(includeValueWithLocalStorage)

              if (!this.sortDesc) {
                await this.initCPlanning({
                  depot_id: this.selectedDeposit,
                  month_id: this.selectedMonth,
                  day_nbr: this.selectedDay,
                  comm_ids: includeValueWithLocalStorage,
                  planning: 'planning-amo',
                  order: 'asc'
                })
              } else {
                await this.initCPlanning({
                  depot_id: this.selectedDeposit,
                  month_id: this.selectedMonth,
                  day_nbr: this.selectedDay,
                  comm_ids: includeValueWithLocalStorage,
                  planning: 'planning-amo',
                  order: 'desc'
                })
              }
              // AFFICAHGE DE PLANNING AMO PAR DATE CURRENT
              this.fetchCPlanningAmo({
                planning: 'planning-amo',
                day_nbr: this.selectedDay,
                comm_ids: includeValueWithLocalStorage
              })
              // SELECTIONNER UN SEUL AMO COMME FILE PROJET
              this.selectedAmoProjetModal(false)
              //
              // localStorage.removeItem('amo')
            }
            // Amo pas selectionner
            if (
              JSON.parse(localStorage.getItem('amo')) &&
              JSON.parse(localStorage.getItem('amo')).amo_id == null
            ) {
              if (!this.sortDesc) {
                await this.initCPlanning({
                  depot_id: this.selectedDeposit,
                  month_id: this.selectedMonth,
                  day_nbr: this.selectedDay,
                  comm_ids: includeValueWithLocalStorage,
                  planning: 'planning-amo',
                  order: 'asc'
                })
              } else {
                await this.initCPlanning({
                  depot_id: this.selectedDeposit,
                  month_id: this.selectedMonth,
                  day_nbr: this.selectedDay,
                  comm_ids: includeValueWithLocalStorage,
                  planning: 'planning-amo',
                  order: 'desc'
                })
              }
              // AFFICAHGE DE PLANNING AMO PAR DATE CURRENT
              this.fetchCPlanningAmo({
                planning: 'planning-amo',
                day_nbr: this.selectedDay,
                comm_ids: includeValueWithLocalStorage
              })
            }
            if (this.computedCommercials && this.computedCommercials.length) {
              let selectedAmo = this.computedCommercials.filter(val =>
                JSON.parse(localStorage.getItem('checkedAmo')).id.includes(
                  val.id
                )
              )
              this.indeterminate = false
              this.allSelected = true
              this.selected = selectedAmo
            }
            // Affichier les amo dispo et non dispo de la liste
            this.groupeAmoListe()
            if (
              this.amoNonDisponibleList &&
              this.amoNonDisponibleList.name &&
              this.amoNonDisponibleList.name.length &&
              !sortBy
            ) {
              this.$refs['modal-center-warning'].show()
            }
          } else {
            // SELECTIONNER TOUS LES CHECKED
            if (this.computedCommercials && this.computedCommercials.length) {
              this.indeterminate = false
              this.allSelected = false
              this.selected = includeValueWithLocalStorage
            }
            // Affichier les amo dispo et non dispo de la liste
            this.groupeAmoListe()
            // ouvrir modal pour informe les amo non existe
            if (
              this.allSelected == this.indeterminate &&
              this.filterCheckedAmo &&
              this.filterCheckedAmo.name &&
              this.filterCheckedAmo.name.length &&
              !sortBy
            ) {
              this.notSelectedAmo = true
              this.$refs['modal-center-warning'].show()
            }
          }
        } else if (
          JSON.parse(localStorage.getItem('amo')) &&
          JSON.parse(localStorage.getItem('checkedAmo')) &&
          JSON.parse(localStorage.getItem('checkedAmo')).id &&
          !JSON.parse(localStorage.getItem('checkedAmo')).id.length
        ) {
          this.fetchPlanningDefaultUrl()
        } else {
          // CAS AMO ET RDV LES DEUX EXISTE and CAS AMO SEULEMENT EXISTE
          // Affichier le button enregistre
          // if (
          //   JSON.parse(localStorage.getItem('amo')).amo_id &&
          //   JSON.parse(localStorage.getItem('amo')).agence &&
          //   JSON.parse(localStorage.getItem('amo')).date_rdv
          // ) {
          //   this.buttonEnregistre = true
          //   this.injecterRdvAMO = false
          // }
          // CAS AMO SEULEMENT EXISTE OU BIEN DATE RDV SEULEMENT
          // // affichier button injecter rdv amo
          // if (
          //   (JSON.parse(localStorage.getItem('amo')).amo_id == null &&
          //     JSON.parse(localStorage.getItem('amo')).date_rdv) ||
          //   (JSON.parse(localStorage.getItem('amo')).date_rdv == null &&
          //     JSON.parse(localStorage.getItem('amo')).amo_id)
          // ) {
          //   if (value_amo && typeof value_amo == 'number') {
          //     this.injecterRdvAMO = false
          //     this.buttonEnregistre = true
          //     // SELECTIONNER UN SEUL AMO COMME FILE PROJET
          //     this.selectedAmoProjetModal(true)
          //   } else {
          //     this.injecterRdvAMO = true
          //     this.buttonEnregistre = false
          //   }
          // }

          // ******************** RFRESH PLANNING AMO ******************************* //
          // // quelque soit les condition de date rdv
          if (
            JSON.parse(localStorage.getItem('amo')) &&
            JSON.parse(localStorage.getItem('amo')).amo_id &&
            JSON.parse(localStorage.getItem('amo')).agence
          ) {
            this.selectedAMO = [JSON.parse(localStorage.getItem('amo')).amo_id]
            let includeValueWithLocalStorage = []
            let amo_id = []
            if (this.computedCommercials && this.computedCommercials.length) {
              amo_id = this.computedCommercials.map(item => item.id)
              includeValueWithLocalStorage = amo_id.filter(val =>
                this.selectedAMO.includes(val)
              )
            }
            if (
              includeValueWithLocalStorage &&
              includeValueWithLocalStorage.length
            ) {
              if (!this.sortDesc) {
                await this.initCPlanning({
                  depot_id: this.selectedDeposit,
                  month_id: this.selectedMonth,
                  day_nbr: this.selectedDay,
                  comm_ids: [JSON.parse(localStorage.getItem('amo')).amo_id],
                  planning: 'planning-amo',
                  order: 'asc'
                })
              } else {
                await this.initCPlanning({
                  depot_id: this.selectedDeposit,
                  month_id: this.selectedMonth,
                  day_nbr: this.selectedDay,
                  comm_ids: [JSON.parse(localStorage.getItem('amo')).amo_id],
                  planning: 'planning-amo',
                  order: 'desc'
                })
              }
              // AFFICAHGE DE PLANNING AMO PAR DATE CURRENT
              this.fetchCPlanningAmo({
                planning: 'planning-amo',
                day_nbr: this.selectedDay,
                comm_ids: [JSON.parse(localStorage.getItem('amo')).amo_id]
              })
              // SELECTIONNER UN SEUL AMO COMME FILE PROJET
              this.selectedAmoProjetModal(false)
            } else {
              // SELECTIONNER TOUS LES CHECKED
              if (this.computedCommercials && this.computedCommercials.length) {
                this.indeterminate = false
                this.allSelected = false
                this.selected = includeValueWithLocalStorage
                this.notSelectedAmo = true
              }
            }
            // Afficher icon filter and affecter  id amo  dans checkedamo
            localStorage.setItem(
              'checkedAmo',
              JSON.stringify({
                id: [JSON.parse(localStorage.getItem('amo')).amo_id],
                name: [
                  JSON.parse(localStorage.getItem('amo')).entity
                    .comm_ite_full_name
                ]
              })
            )
            // Affecter localStorage de checkedAmo
            this.filterCheckedAmo = JSON.parse(
              localStorage.getItem('checkedAmo')
            )
            // Affichier les amo dispo et non dispo de la liste
            this.groupeAmoListe()
            // ouvrir modal pour informe les amo non existe

            if (
              this.allSelected == this.indeterminate &&
              !this.getCPlanningProcessing &&
              !this.getCommercialsCPlanningProcessing
            ) {
              if (
                this.amoNonDisponibleList &&
                this.amoNonDisponibleList.name &&
                this.amoNonDisponibleList.name.length &&
                !sortBy
              ) {
                this.notSelectedAmo = true
                this.$refs['modal-center-warning'].show()
              }
            }
          }
          //  else if (value_amo && typeof value_amo == 'number') {
          //   await this.initCPlanning({
          //     depot_id: this.selectedDeposit,
          //     month_id: this.selectedMonth,
          //     day_nbr: this.selectedDay,
          //     comm_ids: [value_amo],
          //     planning: 'planning-amo'
          //   })
          //   // AFFICAHGE DE PLANNING AMO PAR DATE CURRENT
          //   this.fetchCPlanningAmo({
          //     planning: 'planning-amo',
          //     day_nbr: this.selectedDay,
          //     comm_ids: [value_amo]
          //   })
          // }
          // else
          if (
            JSON.parse(localStorage.getItem('amo')) &&
            JSON.parse(localStorage.getItem('amo')).amo_id == null
          ) {
            this.fetchPlanningDefaultUrl()
          }
        }
      }
      // cas selected amo specifier et plus changement date rdv
      else if (JSON.parse(localStorage.getItem('checkedAmo'))) {
        // si il amo selectionner
        if (
          JSON.parse(localStorage.getItem('checkedAmo')).id &&
          JSON.parse(localStorage.getItem('checkedAmo')).id.length
        ) {
          let includeValueWithLocalStorage = []
          let amo_id = []
          if (this.computedCommercials && this.computedCommercials.length) {
            amo_id = this.computedCommercials.map(item => item.id)
            includeValueWithLocalStorage = amo_id.filter(val =>
              JSON.parse(localStorage.getItem('checkedAmo')).id.includes(val)
            )
          }
          if (
            includeValueWithLocalStorage &&
            includeValueWithLocalStorage.length
          ) {
            if (!this.sortDesc) {
              await this.initCPlanning({
                depot_id: this.selectedDeposit,
                month_id: this.selectedMonth,
                day_nbr: this.selectedDay,
                comm_ids: includeValueWithLocalStorage,
                planning: 'planning-amo',
                order: 'asc'
              })
            } else {
              await this.initCPlanning({
                depot_id: this.selectedDeposit,
                month_id: this.selectedMonth,
                day_nbr: this.selectedDay,
                comm_ids: includeValueWithLocalStorage,
                planning: 'planning-amo',
                order: 'desc'
              })
            }

            // AFFICAHGE DE PLANNING AMO PAR DATE CURRENT
            this.fetchCPlanningAmo({
              planning: 'planning-amo',
              day_nbr: this.selectedDay,
              comm_ids: includeValueWithLocalStorage
            })
            // SELECTIONNER TOUS LES CHECKED

            if (this.computedCommercials && this.computedCommercials.length) {
              let selectedAmo = this.computedCommercials.filter(val =>
                JSON.parse(localStorage.getItem('checkedAmo')).id.includes(
                  val.id
                )
              )
              this.indeterminate = false
              this.allSelected = true
              this.selected = selectedAmo
            }
            // Affichier les amo dispo et non dispo de la liste
            this.groupeAmoListe()
            // ouvrir Modal pour info que il ya des amo non disponible da la liste
            if (
              this.amoNonDisponibleList &&
              this.amoNonDisponibleList.name &&
              this.amoNonDisponibleList.name.length &&
              !sortBy
            ) {
              this.$refs['modal-center-warning'].show()
            }
          } else {
            // SELECTIONNER TOUS LES CHECKED
            if (this.computedCommercials && this.computedCommercials.length) {
              this.indeterminate = false
              this.allSelected = false
              this.selected = includeValueWithLocalStorage
            }
            // Affichier les amo dispo et non dispo de la liste
            this.groupeAmoListe()
            // ouvrir modal pour informe les amo non existe
            if (
              this.allSelected == this.indeterminate &&
              this.notSelectedAmo &&
              this.filterCheckedAmo &&
              this.filterCheckedAmo.name &&
              this.filterCheckedAmo.name.length &&
              !this.getCPlanningProcessing &&
              !this.getCommercialsCPlanningProcessing &&
              !sortBy
            ) {
              this.$refs['modal-center-warning'].show()
            }
          }
        } else {
          this.fetchPlanningDefaultUrl()
        }
      }
      // CHECKED TOUS LES AMO PAR DEFAUT
      else {
        this.fetchPlanningDefaultUrl()
      }
    },
    async fetchPlanningDefaultUrl() {
      this.injecterRdvAMO = false
      this.buttonEnregistre = true
      if (
        this.getListeCommercialsCPlanning &&
        this.getListeCommercialsCPlanning.commercials &&
        this.getListeCommercialsCPlanning.commercials.length
      ) {
        let tab_comm_ids = this.getListeCommercialsCPlanning.commercials.map(
          item => item.id
        )
        if (tab_comm_ids && tab_comm_ids.length) {
          if (!this.sortDesc) {
            await this.initCPlanning({
              depot_id: this.selectedDeposit,
              month_id: this.selectedMonth,
              day_nbr: this.selectedDay,
              comm_ids: tab_comm_ids,
              planning: 'planning-amo',
              order: 'asc'
            })
          } else {
            await this.initCPlanning({
              depot_id: this.selectedDeposit,
              month_id: this.selectedMonth,
              day_nbr: this.selectedDay,
              comm_ids: tab_comm_ids,
              planning: 'planning-amo',
              order: 'desc'
            })
          }
          // AFFICAHGE DE PLANNING AMO PAR DATE CURRENT
          this.fetchCPlanningAmo({
            planning: 'planning-amo',
            day_nbr: this.selectedDay,
            comm_ids: tab_comm_ids
          })
          // SELECTIONNER TOUS LES CHECKED
          this.selectedAllAmoParDefaut()
        }
      }
    },
    async filterParamsUrl(value_amo) {
      for (let index = 0; index < this.getAvailableFiliales.length; index++) {
        const filiales = this.getAvailableFiliales[index]
        filiales.depots.data.forEach(depots => {
          if (depots && depots.id == parseInt(this.$route.params.id_depot)) {
            // select default filiale
            this.selectedFiliale = filiales.id
            // select default deposit
            this.selectedDeposit = this.$route.params.id_depot
          }
        })
      }
      //  CAS DE PARAMS DEPOT ID EGALE NULL / SELECTED PAR DEFAUT 1ERE FILIALE ET 1ERE AGENCE
      if (this.$route.params && this.$route.params.id_depot == null) {
        // select default filiale
        this.selectedFiliale = this.getAvailableFiliales[0].id
        // select default deposit
        if (
          this.getAvailableFiliales[0] &&
          this.getAvailableFiliales[0].depots &&
          this.getAvailableFiliales[0].depots.data &&
          this.getAvailableFiliales[0].depots.data.length
        ) {
          this.selectedDeposit = this.getAvailableFiliales[0].depots.data[0].id
        }
      }
      await this.fetchDeposits(this.selectedFiliale)
      /* ~2) GET commercials AND GLOBAL INFORMATIONS */
      if (!value_amo) {
        if (!this.sortDesc) {
          await this.fetchListeCommercialCPlanning({
            depot_id: this.selectedDeposit,
            month_id: this.$route.params.id_month,
            day_nbr: this.$route.params.id_day,
            planning: 'planning-amo',
            order: 'asc'
          })
        } else {
          await this.fetchListeCommercialCPlanning({
            depot_id: this.selectedDeposit,
            month_id: this.$route.params.id_month,
            day_nbr: this.$route.params.id_day,
            planning: 'planning-amo',
            order: 'desc'
          })
        }
      }
      this.$nextTick(async () => {
        if (this.getListeCommercialsCPlanning) {
          this.selectedYear = this.getListeCommercialsCPlanning.year
          this.selectedMonth = this.getListeCommercialsCPlanning.month_id
          this.selectedMonthNbr = this.getListeCommercialsCPlanning.month_nbr
        }
        // select default month
        this.getAvailableMonths.forEach(month => {
          if (month.id == this.$route.params.id_month) {
            this.selectedMonth = month.id
            this.currentYear = month.year
            this.selectedMonthNbr = month.month_nbr
          }
        })
        // get months of current year
        if (this.currentYear != this.selectedYear) {
          await this.fetchMonths(this.selectedYear)
          this.getAvailableMonths.forEach(month => {
            if (month.id == this.$route.params.id_month) {
              this.selectedMonth = month.id
              this.selectedMonthNbr = month.month_nbr
            }
          })
        }
        // get day
        let month = null
        if (this.selectedMonth) {
          month = this.getAvailableMonths.find(item => {
            if (item.id == this.selectedMonth) {
              return item
            }
          })
        }
        if (month && month.month_nbr) {
          await this.fetchDays({ month: month.month_nbr, year: month.year })
        }
        this.selectedDay = parseInt(this.$route.params.id_day)
        // selected date avec un heure
        if (
          JSON.parse(localStorage.getItem('amo')) &&
          JSON.parse(localStorage.getItem('amo')).date_rdv
        ) {
          let dateTime = JSON.parse(localStorage.getItem('amo')).date_rdv
          let date = dateTime.split(' ')[0]
          let jour = date.split('-')[2]
          if (parseInt(jour) == this.selectedDay) {
            this.selectedDate = moment(
              JSON.parse(localStorage.getItem('amo')).date_rdv,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD/MM/YYYY')
          } else {
            let date = `${this.selectedDay}/${this.selectedMonthNbr}/${this.selectedYear}`
            this.selectedDate = moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')
          }
        } else {
          let date = `${this.selectedDay}/${this.selectedMonthNbr}/${this.selectedYear}`
          this.selectedDate = moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')
        }

        // STYLE INPUT CALENDER
        if (window.innerWidth <= 500 && window.innerWidth > 350) {
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.maxWidth = '370px'
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.minWidth = '223px'
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.width = '306px'
          document.getElementsByClassName(
            'datepicker-container flex flex-fixed flex-1 inline'
          )[0].style.width = '272px'
        } else {
          document.getElementById(
            'undefined-picker-container-DatePicker'
          ).style.width = '224px'
          document.getElementById(
            'undefined-picker-container-DatePicker'
          ).style.height = '253px'
          document.getElementsByClassName('month-container')[0].style.height =
            '190px'
          document.getElementsByClassName(
            'datepicker-day align-center justify-content-center'
          )[0].style.height = '30px'
          document.getElementsByClassName('week-days flex')[0].style.height =
            '32px'
          document.getElementsByClassName(
            'datepicker-controls flex align-center justify-content-center'
          )[0].style.height = '35px'
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.maxWidth = '230px'
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.minWidth = '223px'
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.width = '230px'
          document.getElementsByClassName(
            'datepicker flex flex-direction-column'
          )[0].style.height = '254px'
        }
        this.$router
          .replace(
            '/planning-amo/agence/' +
              this.selectedDeposit +
              '/mois/' +
              this.selectedMonth +
              '/jour/' +
              this.selectedDay
          )
          .catch(() => {})

        this.fetchPlanningCommerciaux(false)
      })
    },
    async filterDefaultUrl() {
      // select default filiale
      if (this.getAvailableFiliales && this.getAvailableFiliales.length) {
        this.selectedFiliale = this.getAvailableFiliales[0].id
      }
      // get deposits
      await this.fetchDeposits(this.selectedFiliale)
      // select default deposit
      if (this.getAvailableDeposits[0]) {
        this.selectedDeposit = this.getAvailableDeposits[0].id
      }
      // get current year
      this.selectedYear = moment().year()
      // get currentMonth
      const currentMonth =
        moment().month() + 1 >= 13 ? 12 : moment().month() + 1
      // select current month
      this.getAvailableMonths.forEach(month => {
        if (month.month_nbr == currentMonth) {
          this.selectedMonth = month.id
          this.currentYear = month.year
        }
      })
      // get months of current year
      if (this.currentYear != this.selectedYear) {
        await this.fetchMonths(this.selectedYear)
        this.getAvailableMonths.forEach(month => {
          if (month.month_nbr == currentMonth) {
            this.selectedMonth = month.id
          }
        })
      }
      // get day
      let month = null
      if (this.selectedMonth) {
        month = this.getAvailableMonths.find(item => {
          if (item.id == this.selectedMonth) {
            return item
          }
        })
      }
      let date = new Date()
      await this.fetchDays({ month: month.month_nbr, year: month.year })
      if (this.getAvailableDays && this.getAvailableDays.length) {
        for (let i = 0; i < this.getAvailableDays.length; i++) {
          if (
            this.getAvailableDays[i].day == moment(date).format('YYYY-MM-DD')
          ) {
            this.selectedDay = this.getAvailableDays[i].day_nbr
            break
          } else {
            this.selectedDay = date.getDay()
          }
        }
      }
      this.$nextTick(() => {
        this.$router
          .replace(
            '/planning-amo/agence/' +
              this.selectedDeposit +
              '/mois/' +
              this.selectedMonth +
              '/jour/' +
              this.selectedDay
          )
          .catch(() => {})
      })
      let today = new Date().toLocaleDateString()
      this.selectedDate = moment(today, 'DD/MM/YYYY').format('DD/MM/YYYY')
      /* ~2) GET commercials AND GLOBAL INFORMATIONS */
      //  LISTE DES AMO
      if (!this.sortDesc) {
        this.fetchListeCommercialCPlanning({
          depot_id: this.selectedDeposit,
          month_id: this.selectedMonth,
          day_nbr: this.selectedDay,
          planning: 'planning-amo',
          order: 'asc'
        }).then(async () => {
          if (this.getListeCommercialsCPlanning) {
            this.selectedYear = this.getListeCommercialsCPlanning.year
            this.selectedMonth = this.getListeCommercialsCPlanning.month_id
          }
          this.fetchPlanningCommerciaux(false)
        })
      } else {
        this.fetchListeCommercialCPlanning({
          depot_id: this.selectedDeposit,
          month_id: this.selectedMonth,
          day_nbr: this.selectedDay,
          planning: 'planning-amo',
          order: 'desc'
        }).then(async () => {
          if (this.getListeCommercialsCPlanning) {
            this.selectedYear = this.getListeCommercialsCPlanning.year
            this.selectedMonth = this.getListeCommercialsCPlanning.month_id
          }
          this.fetchPlanningCommerciaux(false)
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getCPlanning',
      'getAvailableFiliales',
      'getAvailableDeposits',
      'getAvailableYears',
      'getAvailableMonths',
      'getCPlanningError',
      'getCPlanningProcessing',
      'getPlanningPipeline',
      'getAvailableDays',
      'getListeCommercialsCPlanning',
      'getCommercialsCPlanningProcessing',
      'getProjectsError',
      'getProjectsProcessing',
      'getResponseUpdateProjectPlanning'
    ]),
    computedFilialeShow() {
      return function(data) {
        return this.getAvailableFiliales.find(item => item.id == data)
      }
    },
    computedDepotShow() {
      return function(data) {
        return this.getAvailableDeposits.find(item => item.id == data)
      }
    },
    checkDisabled() {
      return function(amo, selected) {
        if (amo && selected && selected.length == 1) {
          if (amo.id === selected[0].id) {
            return false
          } else {
            return true
          }
        }
      }
    },
    computedCommercials: function() {
      if (
        this.getListeCommercialsCPlanning &&
        this.getListeCommercialsCPlanning.commercials &&
        this.getListeCommercialsCPlanning.commercials.length
      ) {
        return this.getListeCommercialsCPlanning.commercials.map(item => {
          return {
            first_name: item.first_name,
            id: item.id,
            last_name: item.last_name,
            zipcode: item.zipcode,
            full_name: item.first_name + ' ' + item.last_name
          }
        })
      }

      return []
    },
    computedStyle() {
      return function(data) {
        if (this.estEcranBureau) {
          if (data && data.length > 4) {
            let length = data.length
            let fois = length * 226
            return {
              width: fois + 'px'
            }
          }
        }
        return {
          width: window.innerWidth - 50 + 'px'
        }
      }
    },
    computedErrors: function() {
      let errors = null
      if (this.localError || this.getPlanningError || this.getProjectsError) {
        errors = []
        if (this.localError && this.localError.length) {
          errors = errors.concat(this.localError)
        }
        if (this.getProjectsError && this.getProjectsError.length) {
          errors = errors.concat(this.getProjectsError)
        }
        if (this.getPlanningError && this.getPlanningError.length) {
          errors = errors.concat(this.getPlanningError)
        }
        // if (this.selectedDeposit && this.selectedDate) {
        //   errors = []
        // }
      }
      return errors
    },
    computedPlanning: function() {
      if (this.getCPlanning) {
        return this.getCPlanning
      }
      return { commercials: [] }
    }
  },
  watch: {
    selected(newValue) {
      // Handle changes in individual amo checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.computedCommercials.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
    $route() {
      if (!this.$route.params.id_depot && !this.$route.params.id_month) {
        this.filterDefaultUrl()
      }
    }
  },
  async mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
    if (JSON.parse(localStorage.getItem('checkedVt'))) {
      localStorage.removeItem('checkedVt')
    }
    if (JSON.parse(localStorage.getItem('vt'))) {
      localStorage.removeItem('vt')
    }
    if (JSON.parse(localStorage.getItem('checkedPose'))) {
      localStorage.removeItem('checkedPose')
    }
    if (JSON.parse(localStorage.getItem('pose'))) {
      localStorage.removeItem('pose')
    }
    localStorage.removeItem('checkedAmo')
    // STYLE INPUT CALENDER
    if (window.innerWidth <= 500 && window.innerWidth > 350) {
      document.getElementsByClassName(
        'datepicker flex flex-direction-column'
      )[0].style.maxWidth = '370px'
      document.getElementsByClassName(
        'datepicker flex flex-direction-column'
      )[0].style.minWidth = '223px'
      document.getElementsByClassName(
        'datepicker flex flex-direction-column'
      )[0].style.width = '306px'
      document.getElementsByClassName(
        'datepicker-container flex flex-fixed flex-1 inline'
      )[0].style.width = '272px'
    } else {
      document.getElementById(
        'undefined-picker-container-DatePicker'
      ).style.width = '224px'
      document.getElementById(
        'undefined-picker-container-DatePicker'
      ).style.height = '253px'
      document.getElementsByClassName('month-container')[0].style.height =
        '190px'
      document.getElementsByClassName(
        'datepicker-day align-center justify-content-center'
      )[0].style.height = '30px'
      document.getElementsByClassName('week-days flex')[0].style.height = '32px'
      document.getElementsByClassName(
        'datepicker-controls flex align-center justify-content-center'
      )[0].style.height = '35px'
      document.getElementsByClassName(
        'datepicker flex flex-direction-column'
      )[0].style.maxWidth = '230px'
      document.getElementsByClassName(
        'datepicker flex flex-direction-column'
      )[0].style.minWidth = '223px'
      document.getElementsByClassName(
        'datepicker flex flex-direction-column'
      )[0].style.width = '230px'
    }
    //
    this.refreshPlanning()
    /* 1) GET FILTERS PARAMS */
    // get filiales
    if (!this.getAvailableFiliales || !this.getAvailableFiliales.length) {
      await this.fetchFiliales()
    }
    // get current year
    await this.fetchYears()
    if (this.$route.params.id_depot && this.$route.params.id_month) {
      this.filterParamsUrl()
    } else {
      this.filterDefaultUrl()
      localStorage.removeItem('amo')
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  position: relative;
  display: block;
  background-color: #fff;
  height: 100%;

  .header {
    display: flex;
    padding: 0 4px;
    height: 69px;
    font-size: 11px;
    align-items: center;
    background: #f6f6f6;
    box-shadow: 0px 0px 8px 3px rgb(218, 218, 218);

    .logo {
      position: relative;
      width: 100px;
      height: 60px;
      margin: 3px 15px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      &.default {
        background: #dbdbdb;
      }
    }

    form {
      display: flex;
      align-items: center;
      height: 100%;

      .filters {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-weight: 700;

        .deposit-number {
          border: 1px solid #ced4da;
          // width: 50px;
          padding: 3px 6px 3px 6px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          color: #007bff;
          margin-left: 6px;
          white-space: nowrap;
        }

        .error {
          color: #ec2f1c;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 1rem;
          margin-top: 5px;
          // display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .head-stat {
      text-align: center;
      font-weight: 700;
      margin: 0 15px;
      color: #383e42;
      .main-stat {
        display: flex;
        justify-content: center;
        position: relative;
        white-space: nowrap;
        .value {
          font-size: 35px;
        }
        .unit {
          font-size: 14px;
          display: block;
          position: relative;
          margin-top: -5px;
        }
      }
      .sub-stat {
        font-size: 14px;
        margin-top: 6px;
        white-space: nowrap;
        font-style: italic;
      }
    }
  }

  .content {
    position: relative;
    display: block;
    width: 100%;
    height: calc(100% - 74px);
    margin-top: 5px;
    overflow: auto;

    .days {
      display: inline-flex;
      position: sticky;
      z-index: 11;
      top: 0;
      transition: 0.5s;
      transition-timing-function: ease-out;
      .day {
        display: block;
        position: sticky;
        left: 50px;
        text-align: center;
        font-size: 10px;
        font-weight: bold;
        border: 1px solid rgba(0, 0, 0, 0.08);
        background-color: #f6f6f6;
        color: #656565;
        height: 24px;
        width: 1080px;
        margin-right: 1px;
        box-shadow: 0px 5px 6px 0px #dadada;
        box-sizing: border-box;

        &.none {
          visibility: hidden;
        }
        &.week {
          position: sticky;
          left: 0;
          margin-right: 20px;
          width: 264px;
          box-shadow: 5px 2px 6px 0px #dadada;
          z-index: 2;
        }

        .icon-wrapper,
        .icon-wrapper-day {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          font-size: 20px;
          padding: 1px 5px;
          cursor: pointer;
          transition: 0.5s;
          transition-timing-function: ease-out;
          &.icon-wrapper {
            display: flex;
          }
        }
      }
      .capitalize {
        text-transform: capitalize;
      }
    }
    .content-wrapper {
      display: inline-flex;
      position: relative;
      min-height: calc(100% - 24px);
      min-width: 100%;
      // transform: translateY(-71px);
      // transition: 0.5s all ease;
      .week-column {
        display: block;
        position: sticky;
        box-shadow: 5px 0px 6px 0px #dadada;
        background-color: #f6f6f6;
        font-weight: bold;
        font-size: 10px;
        color: #656565;
        box-sizing: border-box;
        margin-right: 20px;
        z-index: 10;
        left: 0;
        transition: 0.5s;
        transition-timing-function: ease-out;

        .empty-stat-day {
          opacity: 0;
          display: block;
          text-align: center;
          height: 71px;
          width: 334px;
          padding: 10px;
          transition: 0.5s all ease;
          .content {
            background: #ededed;
            height: 100%;
            margin-top: 0;
          }
        }
        .week-summary-amo {
          display: block;
          // text-align: center;
          // height: 215px;
          // width: 270px;
          width: 264px;
          padding: 10px;
          // transition: 0.2s;
          // transition-timing-function: ease-out;

          .block-week {
            .week-summary-amo-content::-webkit-scrollbar {
              height: 7px;
              width: 8px;
            }
            .week-summary-amo-content::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px #80808054;
              border-radius: 10px;
            }
            .week-summary-amo-content::-webkit-scrollbar-thumb {
              background: #dcdee0;
              border-radius: 10px;
            }
            .week-summary-amo-content::-webkit-scrollbar-thumb:hover {
              background: #888;
            }
            .week-summary-amo-content {
              white-space: normal;
              max-height: 100%;
              height: 92%;
              overflow-y: auto;
              overflow-x: hidden;
              margin-top: -22px;
              padding: 3px 4px;
              position: absolute;
              width: 95%;
              padding-right: 15px;
              .margin-check {
                margin-top: 4px;
                margin-bottom: 0px;
              }
              .check-amo {
                .flex-item {
                  display: flex;
                  align-content: center;
                  align-items: center;
                  justify-content: space-between;
                  .btn-planning {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-right: 10px;
                  }
                  .icon-filtre {
                    padding: 4px 19px 11px 6px;
                    margin-right: -16px;
                    font-size: 12px;
                    border-radius: 44px;
                    height: 25px;
                    width: 11%;
                    cursor: pointer;
                    color: #5e5e5e;
                    background-color: #f6f6f6;
                    &:hover {
                      color: #f6f6f6;
                      background-color: #5e5e5e;
                    }
                    &:focus {
                      box-shadow: unset;
                    }
                  }
                  .filter-icon-sort {
                    left: 2px;
                    position: relative;
                  }
                }
                &.margin {
                  margin-top: 20px;
                }
              }
            }
            .message {
              .error-color {
                color: #ec2f1c;
              }
            }
            .action {
              position: absolute;
              bottom: 5px;
              right: 18px;
              button {
                &:first-child,
                &:last-child {
                  font-size: 11px;
                  margin-right: 9px;
                  padding: 4px;
                }
              }
            }
            .msg-aucun {
              color: #dc3545;
            }
          }
        }
      }
      .days-column {
        display: block;
        transition: 0.5s;
        transition-timing-function: ease-out;

        .days-column-stat-row {
          opacity: 0;
          transition: 0.5s all ease;
        }
      }
      .info-msg {
        border: 1px solid;
        background-color: #f8f9fa;
        width: 60%;
        border-radius: 10px;
        height: 60px;
        margin: auto;
        color: #007bff;
        text-align: center;
        padding: 19px;
      }
    }

    &.day-stat-on {
      .icon-wrapper-day {
        transform: rotate(180deg);
      }

      .days-column-stat-row,
      .empty-stat-day {
        opacity: 1 !important;
      }
      .content-wrapper {
        transform: translateY(0px);
      }
    }
    &.minimised {
      .days {
        transform: translateX(-236px);
        .day {
          .icon-wrapper {
            transform: rotate(539deg);
          }
        }
      }
      .content-wrapper {
        .week-column {
          transform: translateX(-236px);
          // .week-summary-amo {
          //   // transform: rotate(90deg) translateY(-70px);
          // }
        }
        .days-column {
          transform: translateX(-235px);
        }
      }
      .empty-stat-day {
        opacity: 0 !important;
      }
    }
  }

  @media screen and (max-width: 827px) {
    .header {
      .filters {
        .error {
          color: #ec2f1c;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 1rem;
          margin-top: 5px;
          // display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
    .content {
      overflow: hidden;
      .days {
        &.mise-en-page-mobile {
          width: 100%;
          // height: 100%;
          .day {
            &.week {
              .icon-wrapper {
                color: #007bff;
              }
            }
            &.capitalize {
              margin-right: -309px;
            }
          }
        }
      }
      .content-wrapper {
        &.mise-en-page-mobile {
          width: 100%;
          overflow: auto;
          .info-msg {
            display: none;
          }
          .week-column {
            top: 0px;
          }
        }
      }
      &.minimised {
        .content-wrapper {
          &.mise-en-page-mobile {
            width: 100%;
            overflow: auto;
            .info-msg {
              display: block;
              border: 1px solid;
              background-color: #f8f9fa;
              width: 117%;
              border-radius: 10px;
              height: 500%;
              margin: 165px 31px 15px -216px;
              color: #007bff;
              text-align: center;
              padding: 26px 13px 30px 8px;
            }
            .week-column {
              top: 0px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .header {
      height: 69px;
      .logo {
        width: 100%;
        height: 59px;
      }
      .grp-btn {
        display: flex;
        align-items: center;
        margin-left: 19px;
        .filter-btn {
          padding: 3px 8px;
          cursor: pointer;
          display: block;
          // padding: 6px 10px 6px 10px;
          color: #007bff;
          border-color: #007bff;
          border-radius: 5px;
          border: 1px solid;
          &:hover {
            color: #ffffff;
            background-color: #007bff;
          }
        }
        .filters {
          // display: flex;
          // align-items: center;
          // margin-top: -9px;
          // height: 30px;
          button {
            padding: 2px 7px;
            margin: 7px 5px;
          }

          .spinner {
            width: 46px;
            margin-top: -9px;
            margin-left: -16px;
          }
        }
      }
      .show-filiale-agence {
        display: flex;
        align-items: center;
        width: 100%;
        .block-filiale-agence {
          text-align: center;
          display: block;
          border: 1px solid #ced4da;
          padding: 3px 6px 3px 6px;
          // height: 50px;
          border-right: 0px;
          width: 100%;
        }
        .filters {
          display: block;
          width: 100%;
          .deposit-number {
            display: block;
            border: 1px solid #ced4da;
            padding: 7px 4px 8px 4px;
            height: 29px;
            font-weight: bold;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            font-size: 12px;
            color: #007bff;
            margin-left: 0px;
            white-space: nowrap;
          }
        }
        .font {
          font-weight: bold;
          font-size: 10px;
        }
        .font-bleu {
          font-weight: bold;
          font-size: 10px;
          color: #007bff;
          margin-left: 4px;
          white-space: nowrap;
        }
      }
    }
    .content {
      overflow: hidden;
      .days {
        &.mise-en-page-mobile {
          .day {
            &.week {
              width: 100%;
              margin-right: 0px;
            }
            &.capitalize {
              display: none;
            }
          }
        }
      }
      .content-wrapper {
        &.mise-en-page-mobile {
          overflow: hidden;
          width: 100%;
          .week-column {
            // display: contents;
            min-width: inherit;
            .week-summary-amo {
              z-index: 1;
              .block-week {
                .week-summary-amo-content {
                  white-space: normal;
                  max-height: 100%;
                  height: 100%;
                  left: 0px;
                  overflow-y: scroll;
                  overflow-x: hidden;
                  margin-top: -22px;
                  padding: 3px 4px;
                  background-color: #f6f6f6;
                  position: absolute;
                  width: 100%;
                  padding-right: 13px;
                  .input-date-rdv {
                    width: 70%;
                    margin: auto;
                    .icon-date {
                      margin-right: 0px;
                    }
                  }
                  .filter-date-rdv {
                    width: 275px;
                    margin: auto;
                  }
                  .check-amo {
                    padding: 17px 24px 8px 18px;
                    .icon-filtre {
                      font-size: 12px;
                      border-radius: 44px;
                      cursor: pointer;
                      height: 29px;
                      width: 8%;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.minimised {
        .days {
          &.mise-en-page-mobile {
            .day {
              &.week {
                width: 264px;
                margin-right: 20px;
              }
              &.capitalize {
                display: block;
              }
            }
          }
        }
        .content-wrapper {
          &.mise-en-page-mobile {
            overflow: auto;
            .week-column {
              min-width: auto;
              top: 0px;
              .week-summary-amo {
                .block-week {
                  .week-summary-amo-content {
                    white-space: normal;
                    max-height: 100%;
                    height: 100%;
                    left: 0px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    margin-top: -22px;
                    padding: 3px 4px;
                    background-color: #f6f6f6;
                    position: absolute;
                    width: 100%;
                    padding-right: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 412px) {
    .header {
      .show-filiale-agence {
        .block-filiale-agence {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    .content {
      .content-wrapper {
        &.mise-en-page-mobile {
          .week-column {
            .week-summary-amo {
              .block-week {
                .week-summary-amo-content {
                  .filter-date-rdv {
                    width: 226px;
                  }
                  .check-amo {
                    .icon-filtre {
                      font-size: 12px;
                      border-radius: 44px;
                      cursor: pointer;
                      height: 29px;
                      width: 13%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 280px) {
    .content {
      overflow: hidden;
      .content-wrapper {
        &.mise-en-page-mobile {
          overflow: hidden;
          .week-column {
            .week-summary-amo {
              .block-week {
                .week-summary-amo-content {
                  .filter-date-rdv {
                    width: 226px;
                  }
                  .check-amo {
                    .icon-filtre {
                      font-size: 12px;
                      border-radius: 44px;
                      cursor: pointer;
                      height: 29px;
                      width: 16%;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.minimised {
        // overflow-x: auto;
        // overflow-y: hidden;
        .content-wrapper {
          &.mise-en-page-mobile {
            overflow-x: auto;
          }
        }
      }
    }
  }
}
#modal-center-filter {
  .btn-filter-close {
    margin: auto;
    border-radius: 20px;
  }
  .error {
    color: #dc3545;
  }
}
</style>

<style lang="scss">
.page-content {
  .filters {
    .form-group {
      display: flex;
      align-items: center;
      margin: 4px 10px 0px;
      &.planning-filtres {
        label {
          margin-bottom: 0;
          width: 58px;
          text-transform: uppercase;
          color: #656565;
          font-size: 10px;
          margin-right: 8px;
          text-align: right;
        }
        .custom-select {
          width: 150px;
          font-size: 10px;
          color: #656565;
          font-weight: 700;
          height: calc(1.5em + 0.75rem + 1px);
        }
      }
    }

    &.date {
      .form-group {
        &.planning-filtres {
          .custom-select {
            width: 70px;
          }
        }
      }
    }

    .spinner-border {
      width: 1.5rem;
      height: 1.5rem;
      margin: 6px 24px 0px;
    }

    button {
      margin: 0px 20px 0px;
      svg {
        margin-top: 4px;
      }
    }
  }
  .week-summary-amo {
    .block-week {
      display: block;
      &.minimised {
        display: none;
      }
      .input-date-rdv {
        display: flex;
        align-items: center;
        margin-left: 54px;
        margin-top: 4px;
        input {
          border-radius: 0px;
          height: 29px;
          font-size: 12px;
        }
        .icon-date {
          font-size: 23px;
          border: 1px solid #dbdbdb;
          margin-right: 42px;
          cursor: pointer;
          padding: 2px 7px;
          background-color: #eeeff0;
        }
      }
      .filter-date-rdv {
        display: flex;
        align-items: center;
        border: 1px solid #dbdbdb;
        margin-left: 2px;
        width: 226px;
        margin-bottom: 17px;
        // padding-bottom: 10px;
        .date-time-picker {
          width: 89%;
          margin: 0px;
        }
        .datepicker-container {
          .datepicker-days {
            .datepicker-day {
              height: 30px;
            }
          }
        }
        .datepicker-container-label {
          text-transform: capitalize;
          font-size: 16px;
          position: relative;
          height: 26px;
          overflow: hidden;
        }
        .custom-button {
          padding: 0 15px;
        }
        .date {
          margin-bottom: 0;
          width: 58px;
          text-transform: uppercase;
          color: #656565;
          font-size: 11px;
          font-weight: bold;
          margin-right: 8px;
          text-align: right;
        }
        .field-clear-button {
          position: absolute;
          right: 8px;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }
        .field.lg .field-input {
          height: 37px;
          min-height: 37px;
          font-size: 10px;
          border: 1px solid #ced4da;
          color: #656565;
          font-weight: 700;
        }
        .field-input {
          height: 31px;
          min-height: 36px;
          font-size: 10px;
          border: 1px solid #ced4da;
          color: #656565;
          border-radius: 0px;
          font-weight: 700;
        }
      }
    }
  }

  .content {
    &.minimised {
      .content-wrapper {
        .week-column {
          .week-summary-amo {
            table {
              tr {
                height: 37px;
                &:first-child {
                  th {
                    &:first-child {
                      // transform: rotate(-180deg);
                      font-size: 13px;
                      padding: 0 45px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.tooltip {
  z-index: 10001;
  opacity: 1;
}
.amo-tabs {
  .nav-tabs {
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    border: none;
    .amo-tab-title {
      position: relative;
      font-size: 10px;
      font-weight: bold;
      color: #f8f9fa;
      background-color: #2dabe2;
      border: 1px solid #2dabe2;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 4px;
      cursor: pointer;
      user-select: none;
      transition: all 0.4s ease;
      .icon {
        margin-left: 7px;
        border: 1px solid;
        padding: 2px 4px;
        border-radius: 10px;
        &:hover {
          background-color: white;
          color: #2dabe2;
          cursor: pointer;
        }
      }
    }
  }
}
#modal-center-amo {
  .modal-header {
    align-items: center;
    h6 {
      margin-bottom: -5px;
    }
  }
}
#modal-center-warning,
#modal-center-amo {
  .btn-filter-close {
    // margin: auto;
    border-radius: 20px;
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 29px;
    color: #2dabe2;
  }
  .margin-label-info {
    margin-left: 14px;
  }
  .icon-info {
    font-size: 35px;
  }
  .btn-filter-close-info {
    color: #6e6e6e;
    border-color: #6e6e6e;
    border-radius: 20px;
    &:hover {
      color: #fff;
      background-color: #6e6e6e;
      border-color: #6e6e6e;
    }
    &:active {
      color: #fff;
      background-color: #6e6e6e;
      border-color: #6e6e6e;
    }
    &:focus {
      box-shadow: unset;
    }
  }
  .label-div {
    padding: 4px;
    background-color: lightgray;
    text-align: center;
    border: 1px solid lightgray;
    margin: 4px;
  }
  .not-block {
    text-align: center;
    border: 1px solid;
    width: 22%;
    margin: auto;
    color: #2dabe2;
    background-color: #ffffff;
    border-radius: 5px;
  }
  .font {
    font-weight: bold;
  }
  .error {
    color: #dc3545;
  }
}
</style>
